<template>
  <FullScreenDialog
    v-model="paymentDebitDialog"
    title="Modifica spesa per contanti"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in paymentDebitsTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in paymentDebitsTabs" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <component v-if="tabItem.name == 'general'" :readonly="false" :is="tabItem.component" type-props="edit"></component>
              <component v-else :is="tabItem.component" ></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="error"
          @click="close"
        >Annulla</v-btn>
        <v-btn
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
        >Salva</v-btn>
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from '@/components/common/StandardMenu.vue';
import GeneralForm from "./form/GeneralFormTab.vue"
import paymentDebitsService from '@/services/paymentDebits/paymentDebits.service.js'
import paymentDebitForm from '@/services/paymentDebits/paymentDebit.form.js'

export default {
  name: "PaymentTransactionsGivenEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    GeneralForm
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingPaymentDebit: false,
      paymentDebitDialog: this.openDialog,
      paymentDebitsTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      paymentDebit: undefined,
      originalUrl: window.location.pathname,
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    this.id = this.$route.params.id
    if(this.id) {
      this.fetchPaymentDebit()
    }
    let self = this
    paymentDebitForm.on('update', function(data) {
      self.paymentDebit = data.paymentDebit
    })

    self.valid = paymentDebitForm.valid
    paymentDebitForm.on('valid-change', function(valid) {
      self.formValid = valid
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'general'
    },
    filters : {
      type: Array
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.paymentDebitsTabs.length; i++) {
        if(this.paymentDebitsTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      paymentDebitsService.update(this.paymentDebit).then((result) => {
        this.close()
      })
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchPaymentDebit() {
      this.loadingPaymentDebit = true
      paymentDebitForm.resetPaymentDebit()
      paymentDebitsService.get(this.id).then((paymentDebit) => {
        paymentDebit.paymentDebit.ghost = !!paymentDebit.transactions[0].ghostPaid && paymentDebit.transactions[0].ghostPaid > 0
        this.loadingPaymentDebit = false
        paymentDebitForm.updateObject(paymentDebit.paymentDebit)
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.paymentDebitDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.paymentDebitsTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>